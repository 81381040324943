import { __decorate } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import { isToday, isSameDay, parseISO } from 'date-fns';
import { analyticsStore, parceriaCupomHistoricoStore, } from '@/utils/store-accessor';
import PeriodSelectorComponent from '@/components/PeriodSelectorComponent.vue';
import GenericListTotalCardComponent from '@/components/GenericListTotalCardComponent.vue';
import GenericListItemsCardComponent from '@/components/GenericListItemsCardComponent.vue';
import TextExpandableComponent from '@/components/TextExpandableComponent.vue';
import SaveButton from '@/components/SaveButton.vue';
let Agendados = class Agendados extends Vue {
    constructor() {
        super(...arguments);
        this.headers = [
            {
                show: true,
                table: 'parceria_cupom_historico',
                text: 'Data da ligação',
                type: '',
                value: 'parceria_cupom_historico_created_at',
                field: 'created_at',
            },
            {
                show: true,
                table: 'parceria_cupom_historico',
                text: 'Agendado para',
                type: 'timestamp',
                value: 'agendamento',
            },
            {
                show: true,
                table: 'agendador',
                text: 'Agendado por',
                type: 'str',
                value: 'agendador_full_name',
                field: 'full_name',
            },
            {
                show: true,
                table: 'atendente',
                text: 'Recepcionado por',
                type: 'str',
                value: 'atendente_full_name',
                field: 'full_name',
            },
            {
                show: true,
                table: 'prospex',
                text: 'Recepcionado em',
                type: 'str',
                value: 'prospex_created_at',
                field: 'created_at',
            },
            {
                show: true,
                table: 'parceria_cupom',
                text: 'Nome',
                type: 'str',
                value: 'parceria_cupom_nome',
                field: 'nome',
            },
            {
                show: true,
                table: 'parceria_cupom',
                text: 'Telefone',
                type: 'str',
                value: 'telefone',
            },
            {
                show: true,
                table: 'parceria',
                text: 'Parceria',
                type: 'str',
                value: 'parceria_nome',
                field: 'nome',
            },
            {
                show: true,
                table: 'tmp',
                text: 'Status atual',
                type: '',
                value: 'status_id',
            },
            {
                show: true,
                text: 'Status agendamento',
                value: 'parceria_cupom_historico_status_id',
            },
        ];
        this.selectPeriodDate = null;
    }
    get totals() {
        return analyticsStore.totals;
    }
    getStatusPorData(item, status_id) {
        // @ts-ignore
        const status = _.find(this.parceriaCupomStatusOptions(), (i) => i.id == status_id).name;
        if (status !== 'Agendado' || !item.agendamento) {
            return status;
        }
        const dataAtual = new Date();
        dataAtual.setHours(dataAtual.getHours() + 2);
        const dataAgendamento = parseISO(item.agendamento);
        if (dataAtual > dataAgendamento) {
            if (item.prospex_created_at && isSameDay(dataAgendamento, parseISO(item.prospex_created_at))) {
                return 'Compareceu';
            }
            return 'Não compareceu';
        }
        if (isToday(dataAgendamento)) {
            return 'Agendado para hoje';
        }
        return status;
    }
    async buscar() {
        analyticsStore.setDefaultFilters({
            'parceria_cupom_historico.agendamento': {
                type: 'timestamp',
                value: [this.selectPeriodDate[0], this.selectPeriodDate[1]],
            },
            // agendado, não comparecido, reagendado, prospectado, matriculado
            'parceria_cupom_historico.status_id': {
                type: 'int',
                value: [3, 8, 4, 12, 13]
            }
        });
        await this.doSearchTotals();
        await analyticsStore.executeSearchItems();
        // @ts-ignore
        await this.$refs.loadingButton.changeLoadingStatus(false);
    }
    async doSearchItems(queryIn) {
        return await parceriaCupomHistoricoStore.getParceriaCupomHistoricoQuery(queryIn);
    }
    async doSearchTotals() {
        await analyticsStore.getAnalyticsCadernoAgendado({
            data_inicial: this.selectPeriodDate[0],
            data_final: this.selectPeriodDate[1],
        });
    }
    async mounted() {
        analyticsStore.initAnalytics();
        analyticsStore.setFnSearchItems(parceriaCupomHistoricoStore.getParceriaCupomHistoricoQuery);
        analyticsStore.setHeaders(this.headers);
        // tela que tem defaultFilter nao precisa ter o buscar() aqui
    }
};
Agendados = __decorate([
    Component({
        components: {
            PeriodSelectorComponent,
            GenericListTotalCardComponent,
            GenericListItemsCardComponent,
            TextExpandableComponent,
            SaveButton,
        },
    })
], Agendados);
export default Agendados;
