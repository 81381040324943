var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"mt-0 pt-0",attrs:{"fluid":""}},[_c('v-card',{staticClass:"ma-1 pa-3 rounded-xl mb-3"},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('div',{staticClass:"headline primary--text"},[_vm._v("Caderno de agendados")])]),_c('v-card-text',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('period-selector-component',{attrs:{"prop-title":"Data do agendamento"},model:{value:(_vm.selectPeriodDate),callback:function ($$v) {_vm.selectPeriodDate=$$v},expression:"selectPeriodDate"}})],1),_c('v-col',[_c('SaveButton',{ref:"loadingButton",attrs:{"prop-show-voltar":false,"propSubmitLabel":"Buscar"},on:{"submit":function($event){return _vm.buscar()}}})],1)],1),_c('v-row',[(_vm.totals.por_cda)?_c('v-col',{attrs:{"cols":"6"}},[_c('GenericListTotalCardComponent',{attrs:{"prop-title":"Por agendador","prop-items":_vm.totals.por_cda}})],1):_vm._e(),(_vm.totals.por_cda)?_c('v-col',{attrs:{"cols":"6"}},[_c('GenericListTotalCardComponent',{attrs:{"prop-title":"Por recepcionista","prop-items":_vm.totals.por_atendente},scopedSlots:_vm._u([{key:"item.label",fn:function(ref){
var item = ref.item;
return [(!item.label)?_c('span',[_vm._v(" *Não informado ")]):_c('span',[_vm._v(" "+_vm._s(item.label)+" ")])]}}],null,true)})],1):_vm._e(),(_vm.totals.por_parceria)?_c('v-col',{attrs:{"cols":"4"}},[_c('GenericListTotalCardComponent',{attrs:{"prop-title":"Por parceria","prop-items":_vm.totals.por_parceria}})],1):_vm._e(),(_vm.totals.por_status_cupom_historico)?_c('v-col',{attrs:{"cols":"4"}},[_c('GenericListTotalCardComponent',{attrs:{"prop-title":"Por status agendamento","prop-items":_vm.totals.por_status_cupom_historico}})],1):_vm._e(),(_vm.totals.por_status_cupom)?_c('v-col',{attrs:{"cols":"4"}},[_c('GenericListTotalCardComponent',{attrs:{"prop-title":"Por atual","prop-items":_vm.totals.por_status_cupom}})],1):_vm._e()],1),(_vm.totals.por_parceria)?_c('v-row',[_c('v-col',[_c('GenericListItemsCardComponent',{attrs:{"propTableName":"list_agendados"},scopedSlots:_vm._u([{key:"item.agendamento",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("formatDateTimeWithoutConvert")(value))+" ")]}},{key:"item.parceria_cupom_historico_created_at",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("formatDateTime")(value))+" ")]}},{key:"item.prospex_created_at",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("formatDateTime")(value))+" ")]}},{key:"item.parceria_cupom_historico_status_id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getStatusPorData(item, item.parceria_cupom_historico_status_id))+" ")]}},{key:"item.status_id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getStatusPorData(item, item.parceria_cupom_status_id))+" ")]}}],null,false,548312569)})],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }